$(window).on("load", function() {

	$('#supports-impression .support').on('click', function() {
        let oldSelected = document.getElementById("supports-impression").getElementsByClassName("selected")[0];
        let supportId = this.dataset.supportId;

        // Add value to hidden inputs
        let hiddenSupport = document.getElementById('custom_support');
        hiddenSupport.value = supportId;
        let hiddenEchantillonSupport = document.getElementById('echantillon_support');
        let btnEchantillon = document.getElementById('add_echantillon');

        if(oldSelected) {
            oldSelected.classList.remove('selected');
        }

        this.classList.add("selected");

        let productSupport = document.getElementById("product-support");
        let productSupportNameSelect = productSupport.getElementsByClassName("support")[0];

        $.ajax({
            type: 'POST',
            url: '/wp-admin/admin-ajax.php',
            data: {
                'action': 'get_json_support_name_by_ID',
                'support_id': supportId
            },
            dataType: "json",
        }).done(function(response) {
            productSupportNameSelect.innerHTML = response;
            hiddenEchantillonSupport.value = response;
            btnEchantillon.disabled = false;
        });

        let productWidth = document.getElementById("product-width");
        let productHeight = document.getElementById("product-height");

        if(productWidth.value !== '' && productHeight.value !== '') {
            updatePrice(this.dataset.supportId);
        }
    });

    $('#type-impression input').on('change', function() {
        if(this.value == 'recto' || this.value == 'verso') {
            let customImpression = document.getElementById("custom_impression");
            customImpression.value = this.value;
            
            let supportSelected = document.getElementById("supports-impression").getElementsByClassName("selected")[0];

            let productWidth = document.getElementById("product-width");
            let productHeight = document.getElementById("product-height");

            if(supportSelected && productWidth.value !== '' && productHeight.value !== '') {
                let support_id = supportSelected.dataset.supportId;
                updatePrice(support_id);
            }
        }
    });

    $(document).on('change', '#pa_supports', function() {
        let hiddenEchantillonSupport = document.getElementById('echantillon_support');
        hiddenEchantillonSupport.value = this.options[this.selectedIndex].text;
        let btnEchantillon = document.getElementById('add_echantillon');
        btnEchantillon.disabled = false;
    });

    function activateAddEchantillon() {
        const supportSelect = document.getElementById('#pa_supports');
        let hiddenEchantillonSupport = document.getElementById('echantillon_support');
        hiddenEchantillonSupport.value = supportSelect.options[supportSelect.selectedIndex].text;
        let btnEchantillon = document.getElementById('add_echantillon');
        btnEchantillon.disabled = false;
    }

    $(".single-product input[type=number]").on("keydown", function(e) {
        var invalidChars = ["-", "+", "e", "E"];
        if(invalidChars.includes(e.key)) {
            e.preventDefault();
        }
    });

    $('#product-width').on('change', function() {
        let divProductFormat = document.getElementById("product-format");
        let widthDiv = divProductFormat.getElementsByClassName("width")[0];
        let hiddenWidth = document.getElementById('custom_width');

        const min = this.min;
        const max = this.max;
        let width = this.value;

        if((parseInt(min) <= parseInt(width)) && (parseInt(width) <= parseInt(max))) {
            hiddenWidth.value = width;
            widthDiv.innerHTML = width;

            updateSurface();
        } else {
            createModal('Mauvaise largueur indiquée', 'La taille indiquée en largueur ne corresponds pas aux dimensions minimales & maximales autorisées.');

            let btnAddToCart = document.getElementsByClassName('single_add_to_cart_button');
            btnAddToCart[0].disabled = true;
        }
    });

    $('#product-height').on('change', function() {
        let divProductFormat = document.getElementById("product-format");
        let heightDiv = divProductFormat.getElementsByClassName("height")[0];
        let hiddenHeight = document.getElementById('custom_height');

        const min = this.min;
        const max = this.max;
        let height = this.value;

        if((parseInt(min) <= parseInt(height)) && (parseInt(height) <= parseInt(max))) {
            hiddenHeight.value = height;
            heightDiv.innerHTML = height;

            updateSurface();
        } else {
            createModal('Mauvaise hauteur indiquée', 'La taille indiquée en hauteur ne corresponds pas aux dimensions minimales & maximales autorisées.');

            let btnAddToCart = document.getElementsByClassName('single_add_to_cart_button');
            btnAddToCart[0].disabled = true;
        }
    });

    function updateSurface() {
        let divProductFormat = document.getElementById("product-format");

        let width = divProductFormat.getElementsByClassName("width")[0];
        let height = divProductFormat.getElementsByClassName("height")[0];

        surface = parseInt(width.innerHTML) * parseInt(height.innerHTML) * 0.0001;
        surface = surface.toFixed(2);

        let divProductSurface = document.getElementById("product-surface");
        let surfaceDiv = divProductSurface.getElementsByClassName("surface")[0];
        surfaceDiv.innerHTML = surface;

        let supportSelected = document.getElementById("supports-impression").getElementsByClassName("selected")[0];

        let productWidth = document.getElementById("product-width");
        let productHeight = document.getElementById("product-height");

        if(supportSelected && productWidth.value !== '' && productHeight.value !== '') {
            let support_id = supportSelected.dataset.supportId;
            updatePrice(support_id);
        }
    }

    function getSurface() {
        let divProductSurface = document.getElementById("product-surface");
        let surfaceDiv = divProductSurface.getElementsByClassName("surface")[0];
        surface = surfaceDiv.innerHTML;

        return surface;
    }

    function updatePrice(support_id) {
        let surface = getSurface();
        let impression = false;

        if(document.getElementById("custom_impression")) {
            if(document.getElementById("custom_impression").value == 'verso') {
                impression = 'verso';
            }
        }

        $.ajax({
            type: 'POST',
            url: '/wp-admin/admin-ajax.php',
            data: {
                'action': 'get_json_product_final_price',
                'support_id': support_id,
                'surface' : surface,
                'impression' : impression
            },
            dataType: "json",
        }).done(function(response) {
            let priceDiv = document.getElementById('product-price');

            priceDiv.innerHTML = response;

            let btnAddToCart = document.getElementsByClassName('single_add_to_cart_button');
            btnAddToCart[0].disabled = false;
        });
    }

    /*-------------------------------------------
	createModal Function
    ---------------------------------------------*/
    function createModal(title, content) {
        let modalTrigger = document.createElement('button');
        modalTrigger.classList.add('d-none');
        modalTrigger.setAttribute('id', 'btn-call-ajax-modal');
        modalTrigger.setAttribute('type', 'button');
        modalTrigger.setAttribute('data-bs-toggle', 'modal');
        modalTrigger.setAttribute('data-bs-target', '#ajax-modal');

        let modal = document.createElement('div');
        modal.classList.add('modal', 'fade');
        modal.setAttribute('id', 'ajax-modal');
        modal.setAttribute('tabindex', '-1');
        modal.setAttribute('aria-hidden', 'true');

        let modalWrap = document.createElement('div');
        modalWrap.classList.add('modal-dialog', 'modal-dialog-centered');

        let modalContent = document.createElement('div');
        modalContent.classList.add('modal-content');

        let modalHeader = document.createElement('div');
        modalHeader.classList.add('modal-header', 'bg-yellow', 'text-white');

        let modalTitle = document.createElement('h5');
        modalTitle.classList.add('modal-title', 'fw-bold');
        modalTitle.innerHTML = title;

        let button = document.createElement('button');
        button.classList.add('btn-close');
        button.setAttribute('type', 'button');
        button.setAttribute('data-bs-dismiss', 'modal');
        button.setAttribute('aria-label', 'Close');

        // let span = document.createElement('span');
        // span.setAttribute('aria-hidden', 'true');
        // span.innerHTML = '&times;';

        let modalBody = document.createElement('div');
        modalBody.classList.add('modal-body', 'text-center');
        
        // button.appendChild(span);

        modalHeader.appendChild(modalTitle);
        modalHeader.appendChild(button);

        if(content.nodeType == 1) {
            modalBody.appendChild(content);
        } else {
            modalBody.innerHTML = content;
        }

        modalContent.appendChild(modalHeader);
        modalContent.appendChild(modalBody);

        modalWrap.appendChild(modalContent);

        modal.appendChild(modalWrap);

        const parent = document.getElementById('contenu');
        parent.appendChild(modalTrigger);
        parent.appendChild(modal);
        
        $('#btn-call-ajax-modal').trigger('click');

        $('#ajax-modal').on('hidden.bs.modal', function() {
            $('#btn-call-ajax-modal').remove();
            $(this).remove();
        });
    }
});
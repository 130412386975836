$(window).on("load", function() {

    $('.btn-switch-univers').on('click', function() {
        let universID = this.dataset.univers;

        if(!$(this).hasClass('current')) {
            $('.btn-switch-univers.current').removeClass('current');
            $(this).addClass('current');
            $('.content-univers').fadeOut();
            $('#content-univers-' + universID).fadeIn();
        }
    });
});
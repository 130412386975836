$('#search-btn').on('click', function() {
    $('#search-bar').fadeIn();
    $('#search-bar').addClass('show');
    $(this).fadeOut();
});

// Search BAR
$('#search-close').on('click', function(e) {
    e.preventDefault();
    e.stopPropagation();
    $('#search-bar').fadeOut(400, function() {
        $('#search-bar').removeClass('show');
    });
    $('#search-btn').fadeIn();
});
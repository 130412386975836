jQuery(function($) {
	$('.btn-loadmore').on('click', function() {
		var maxNumPages = this.dataset.max_num_pages;
		
		var button = $(this),
			data = {
			'action': 'loadmore',
			'query': loadmore_params.posts,
			'page' : loadmore_params.current_page
		};

		$.ajax({
			url : '/wp-admin/admin-ajax.php',
			data : data,
			type : 'POST',
			beforeSend : function (xhr) {
				button.text('Chargement...');
			},
			success : function(data){
				if(data) {
					button.text('En voir +').closest('.btn-wrap').prev().after(data);
                    
                    $('.product-ajax-loaded').hide().fadeIn(1000, function() {
                        $('.product-ajax-loaded').removeClass('product-ajax-loaded');
                    });

					loadmore_params.current_page++;

					if(loadmore_params.current_page == maxNumPages) {
						button.remove();
                    }
				} else {
					button.remove();
				}
			}
		});
	});
});